import React, { useRef, useState } from "react";
import styled from "styled-components";
import "./RegisterPopup.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faEnvelope,
  faLocationDot,
  faMobile,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { get, post } from "../utils/functions";
import Phone from "../assets/Phone.svg";
import Lock from "../assets/Lock.svg";
import { toast } from "react-hot-toast";
import { CircularProgress } from "@mui/material";
import useOnClickOutside from "../utils/useOnClickOutside";

function RegisterPopup(props) {
  const [object, setObject] = useState({
    name: "",
    phone: "",
    otp: "",
    state: "",
    district: "",
    email: "",
    isSend: false,
    districts: [],
    states: [],
  });
  const [buttonLoading, setButtonLoading] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const ref = useRef(null);
  useOnClickOutside(ref, props.onClose);

  const onStateChange = (e) => {
    setObject({ ...object, state: e.target.value, district: "" }); // Reset district on state change
  };
  
  const onDistrictChange = (e) => {
    setObject({ ...object, district: e.target.value });
  };

  const validatePhoneNumber = (number) => {
    if (number === "") {
      toast.error("Please Enter Your Phone");
      return false;
    }
    if (number.length !== 10) {
      toast.error("Invalid Phone Number");
      return false;
    }
    return true;
  };

  const validateEmail = (email) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email regex
    if (!emailPattern.test(email)) {
      toast.error("Please Enter a Valid Email Address");
      return false;
    }
    return true;
  };

  const onSendOtp = async (event) => {
    event.preventDefault();
    setOtpLoading(true);
    
    if (!validatePhoneNumber(object.phone)) {
      setOtpLoading(false);
      return;
    }

    try {
      const resp = await get("/user/sendotp", { mobile_no: object.phone });
      if (resp.status_code === 200) {
        setObject((prev) => ({ ...prev, isSend: true }));
        toast.success("OTP sent successfully");
      } else {
        toast.error(resp?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error.message);
    } finally {
      setOtpLoading(false);
    }
  };

  const getStates = async () => {
    try {
      const resp = await get("/general/rto/state");
      if (resp.status_code === 200) setObject((prev) => ({ ...prev, states: resp.data }));
    } catch (error) {
      console.error(error);
    }
  };

  const getDistrict = async () => {
    if (!object.state) return; // Prevent fetching districts if no state is selected

    try {
      const resp = await get(`/general/rto/code/state/${object.state}`);
      if (resp.status_code === 200) setObject((prev) => ({ ...prev, districts: resp.data }));
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    // setButtonLoading(true);

    

    try {

      if (!validatePhoneNumber(object.phone) || !validateEmail(object.email)) {
        setButtonLoading(false);
        return;
      }
      const resp = await post(
        "/user/register",
        {
          name: object.name,
          state_id: object.state,
          district_id: object.district,
          email: object.email,
          mobile_no: object.phone,
          otp: object.otp,
        },
        { "Content-Type": "application/json" }
      );

      if (resp.status_code === 200) {
        const { token, refresh_token } = resp.data;
        const date = new Date().toISOString();
        localStorage.setItem("token", token);
        localStorage.setItem("refresh_token", refresh_token);
        localStorage.setItem("token_date", date);
        localStorage.setItem("account", JSON.stringify(resp.data));
        toast.success(resp?.message);
        props.onClose();
      } else {
        toast.error(resp?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error.message);
    } finally {
      // setButtonLoading(false);
    }
  };

  return (
    <div className="login-popup">
      <div ref={ref} className="container-inner">
        <div>
          <ul className="tab-group">
            <li className="tab1">
              <button onClick={() => { props.onLogin(); props.onClose(); }}>Login</button>
            </li>
            <li className="tab1 active">
              <button>Register</button>
            </li>
          </ul>
        </div>
        <div className="register-popup-inner">
          <div className="registerBar">
            <h1>Register</h1>
            <button className="close-btn" onClick={props.onClose}>
              <FontAwesomeIcon icon={faXmark} className="cross" />
            </button>
          </div>
          <form onSubmit={onSubmit}>
            <label>
              <div className="input-container">
                <FontAwesomeIcon icon={faEnvelope} className="mobile-icon" />
                <input
                  type="text"
                  value={object.name}
                  onChange={(e) => setObject({ ...object, name: e.target.value })}
                  placeholder="Name"
                  required
                />
              </div>
            </label>
            <label>
              <div className="input-container">
                <img src={Phone} className="mobile-icon" alt="Phone" />
                <input
                  type="number"
                  value={object.phone}
                  className="phone-input"
                  onChange={(e) => setObject({ ...object, phone: e.target.value })}
                  placeholder="Phone No."
                  required
                />
                {otpLoading ? (
                  <CircularProgress color="inherit" size={25} />
                ) : (
                  <button type="button" onClick={onSendOtp}>GET OTP</button>
                )}
              </div>
            </label>
            <label>
              <div className="input-container">
                <img src={Lock} className="mobile-icon" alt="Lock" />
                <input
                  type="tel"
                  value={object.otp}
                  onChange={(e) => setObject({ ...object, otp: e.target.value })}
                  placeholder="OTP"
                  required
                />
              </div>
            </label>
            <label>
              <div className="input-container">
                <FontAwesomeIcon icon={faEnvelope} className="mobile-icon" />
                <input
                  type="email"
                  value={object.email}
                  onChange={(e) => setObject({ ...object, email: e.target.value })}
                  placeholder="Email Address"
                  required
                />
              </div>
            </label>
            <label>
              <div className="input-container">
                <FontAwesomeIcon icon={faLocationDot} className="mobile-icon" />
                <select
                  value={object.state}
                  onChange={onStateChange}
                  onClick={getStates}
                  required
                >
                  <option value="">Select State</option>
                  {object.states.map((state) => (
                    <option value={state?.id} key={state?.id}>
                      {state?.state}
                    </option>
                  ))}
                </select>
              </div>
            </label>
            <label>
              <div className="input-container">
                <FontAwesomeIcon icon={faLocationDot} className="mobile-icon" />
                <select
                  value={object.district}
                  onChange={onDistrictChange}
                  onClick={getDistrict}
                  required
                >
                  <option value="">Select District</option>
                  {object.districts.map((district) => (
                    <option value={district.id} key={district?.id}>
                      {district?.district}
                    </option>
                  ))}
                </select>
              </div>
            </label>
            <button
              type="submit"
              // disabled={!object.isSend || buttonLoading}
            >
              {/* {buttonLoading ? (
                <CircularProgress color="inherit" size={25} />
              ) : (
                "Create Account"
              )} */}
              Create Account
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default RegisterPopup;
