import React, { useEffect, useState } from "react";
// import { Helmet } from "react-helmet";
import { Navbar, ContactStrip, Footer } from "../components";
import Wrapper from "../wrappers/Common";
import axios from "axios";
import qs from "qs";
import "./Contactus.css";
import toast from "react-hot-toast";
import { root_url } from "../utils/url.constant";
// import { ReactComponent as Location } from "../assets/location.svg";
// import { ReactComponent as Mail } from "../assets/MailIcon.svg";
// import { ReactComponent as Phone } from "../assets/Phone.svg";
// import { ReactComponent as Person } from "../assets/person.svg";
import SubmitSection from "../components/VehicleRequirementComponent/SubmitSection";
import Select from "react-select";
import { Link } from "react-router-dom";

const Contact = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const [stateArr, setStateArr] = useState([]);
  // const [district, setDistrict] = useState([]);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [vehicle, setVehicle] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [state, setState] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("");
  const [year, setYear] = useState("");
  const [makeList, setMakeList] = useState([]);

  const [make, setMake] = useState("");
  const [modelList, setModelList] = useState([]);

  const [budget, setBudget] = useState("");
  const [paymentMode, setPaymentMode] = useState("Cash");
  const [description, setDescription] = useState("");

  const [model, setModel] = useState();

  // const [data, setData] = useState({
  //   fullName: "",
  //   mobileNumber: "",
  //   email: "",
  //   message: "",
  // });

  useEffect(() => {
    axios
      .get(`${root_url}/location/states`)
      .then((res) => {
        setStateArr(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const onVehicleChange = (val) => {
    setVehicle(val);
    getMakeUsingVehicle(val);
  };

  const getMakeUsingVehicle = (vehicleType) => {
    axios
      .get(`${root_url}/vehicle/brands?type=${vehicleType}`)
      .then((res) => {
        setMakeList(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onMakeChange = (val) => {
    setMake(val);
    getModelUsingMake(val.value);
  };

  const getModelUsingMake = (brnad_id) => {
    const url = `${root_url}/vehicle/models/variants/brands/${brnad_id}?type=${vehicle}`;
    axios
      .get(url)
      .then((res) => {
        // Filter out duplicate model names
        setModelList(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const onChnageHandler = (e) => {
  //   let name = e.target.name;
  //   let value = e.target.value;

    // if (name === "make") {
    //   value = makeList.filter((item) => item.brand_id == value)[0];
    // }

    // if (name === "state") {
    //   value = state.filter((item) => item.id == value)[0];
    // }

  //   setData({ ...data, [name]: value });
  // };

  const appendSpreadsheet = async () => {
    if (handleDisbaled()) {
      toast.error("Please fill all the detail");
      return;
    }

    try {
      let dataObj = qs.stringify({
        name,
        email,
        phone,
        state,
        city,
        message: description,
        vehicle: vehicle,
        make: make?.label,
        model: model?.label,
        year,
        budget,
        paymentMode,
      });

      console.log(dataObj, "dataObjdataObjdataObj");
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${root_url}/buy/contact`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: dataObj,
      };

      axios
        .request(config)
        .then((response) => {
          if (response.data?.status_code === 400) {
            toast.error(response?.data?.message);
          } else {
            setIsSubmitted(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
      console.log(e);
    }
  };
  const handleDisbaled = () => {
    let disbaled = true;
    disbaled =
      name && phone && email && description && make && model && year && state;

    return !disbaled;
  };

  // const options = [
  //   { value: "chocolate", label: "Chocolate" },
  //   { value: "strawberry", label: "Strawberry" },
  //   { value: "vanilla", label: "Vanilla" },
  // ];
  // const modelnumber = [
  //   { value: "02", label: "02" },
  //   { value: "003", label: "003" },
  //   { value: "4662", label: "4662" },
  // ];

  const customStyles = {
    control: (base) => ({
      ...base,
      border: "1px solid #e0e0e0 !important",
      boxShadow: "0 !important",
      padding: "8px",
      zindex: "3",
      minHeight: "45px",
      fontSize: "1.4rem",
      fontFamily: "Mulish",
      fontWeight: "500",
      color: "#616161",
      "&:hover": {
        // border: '2px solid #D9D9D9 !important',
      },
    }),
    option: (base) => ({
      ...base,
      cursor: "pointer",
      background: "#fff",
      fontSize: "1.4rem",
      color: "#000",
      ":hover": {
        backgroundColor: "#404041",
        color: "#fff",
      },
    }),
  };

  return (
    <Wrapper>
      <Navbar search={false} />
      <div className="vehicle-req-container mobilewidt95">
        {!isSubmitted ? (
          <div className="personal-detail-main-box">
            <h2 className="headingmain">Contact Us</h2>
            <p className="pgrcontact">
              We are here to assist you with any questions or concerns.
            </p>

            <div className="customeline"></div>

            <h5 className="perheading">Personal Information</h5>

            <div className="w-100">
              <div className="position-relative mb-4">
                {/* <Person className="input-field-icon" /> */}
                <input
                  type="text"
                  className="cusomtinput w100"
                  placeholder="Enter Your name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
            </div>

            <div className="grid_column">
              <div className="input_group">
                <input
                  type="text"
                  className="cusomtinput"
                  placeholder="Enter Your Mobile Number"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </div>
              <div className="input_group">
                <input
                  type="text"
                  className="cusomtinput"
                  placeholder="Enter Your Email id"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="input_group">
                <select
                  name="state"
                  className="cusomtinput"
                  onChange={(e) => setState(e.target.value)}
                  value={state}
                >
                  <option>Select State</option>
                  {stateArr?.length > 0
                    ? stateArr.map((item) => (
                        <option value={item.state_name}>
                          {item.state_name}
                        </option>
                      ))
                    : null}
                </select>
              </div>
              <div className="input_group">
                <input
                  type="text"
                  className="cusomtinput"
                  placeholder="Enter your City"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </div>
            </div>

            <h5 className="perheading mt-5"> Truck Details</h5>

            <div className="grid_column">
              <div className="input_group">
                <select
                  className="cusomtinput"
                  value={vehicle}
                  onChange={(e) => onVehicleChange(e.target.value)}
                >
                  <option value="">Please Select Vehicle</option>
                  <option value="Truck">Truck</option>
                  <option value="Bus">Bus</option>
                  <option value="Mini Truck">Mini Truck</option>
                  <option value="Tipper">Tipper</option>
                  <option value="Trailer">Trailer</option>
                  <option value="3 Wheeler">3 Wheeler</option>
                  <option value="Auto Rickshaw">Auto Rickshaw</option>
                  <option value="Traveller">Traveller</option>
                  <option value="Transit Mixer">Transit Mixer</option>
                  <option value="E Rickshaw">E Rickshaw</option>
                </select>
              </div>
              <div className="input_group">
                {/* <select
                  className="cusomtinput"
                  value={make}
                  onChange={(e) => onMakeChange(e.target.value)}
                >
                  <option value="">Please Select Make</option>
                  {makeList.map((make) => (
                    <option value={make.brand_id}>{make.brand_name}</option>
                  ))}
                </select> */}
                <Select
                  styles={customStyles}
                  options={makeList.map((combined) => ({
                    label: combined.brand_name,
                    value: combined.brand_id,
                  }))}
                  value={make}
                  onChange={(val) => onMakeChange(val)}
                />
              </div>
              <div className="input_group">
                <Select
                  styles={customStyles}
                  options={
                    Object.values(modelList)?.length > 0
                      ? Object.values(modelList)
                          .flatMap((item) =>
                            item.variants.map((variant) => ({
                              model_id: item.model.model_id,
                              model_name: item.model.model_name,
                              variant_id: variant.variant_id,
                              variant_name: variant.variant_name,
                            }))
                          )
                          .map((combined) => ({
                            label:
                              combined.model_name +
                              " - " +
                              combined.variant_name,
                            value: combined.variant_id,
                          }))
                      : []
                  }
                  value={model}
                  onChange={(val) => {
                    setModel(val);
                  }}
                />
              </div>
              <div className="input_group">
                <input
                  type="text"
                  className="cusomtinput"
                  placeholder="Enter Manufacture Year"
                  value={year}
                  onChange={(e) => setYear(e.target.value)}
                />
              </div>
            </div>
            <div className="grid_column">
              <div className="input_group">
                <input
                  type="checkbox"
                  id="vehicle1"
                  name="vehicle1"
                  value="Bike"
                />
                <label for="vehicle1" className="confromfont">
                  {" "}
                  Are you financing your Vehicle ?
                </label>
              </div>
            </div>

            <h5 className="perheading mt-5"> Budget</h5>

            <div className="grid_column">
              <div className="input_group">
                <input
                  type="text"
                  className="cusomtinput"
                  placeholder="Enter Your Budget"
                  value={budget}
                  onChange={(e) => setBudget(e.target.value)}
                />
              </div>
              <div className="input_group">
                <h6 className="modecss">Mode</h6>

                <div className="model_list">
                  <div className="radibtuton">
                    <input
                      type="radio"
                      id="Cash"
                      name="finance"
                      value="Cash"
                      checked={paymentMode === "Cash"}
                      onChange={(e) => setPaymentMode("Cash")}
                    />
                    <label for="Cash">Cash</label>
                  </div>
                  <div className="radibtuton">
                    <input
                      type="radio"
                      id="css"
                      name="finance"
                      value="Finance"
                      checked={paymentMode === "Finance"}
                      onChange={(e) => setPaymentMode("Finance")}
                    />
                    <label for="Finance">Finance</label>
                  </div>
                </div>
              </div>
            </div>

            <div className="">
              <h6 className="perheading mt-5" style={{ marginBottom: "10px" }}>
                Additional Requirements
              </h6>
              <textarea
                name=""
                id=""
                className="w100textrea"
                cols={9}
                rows={5}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Please provide any specific details or requirements you may have"
              ></textarea>
            </div>
            <button
              className="submitbutton"
              onClick={() => appendSpreadsheet()}
            >
              Submit
            </button>

            <div className="bottombarfooter">
              <div className="col-8">
                <h5>GET IT FINANCED</h5>
                <h2>
                  Get behind the wheel of your dream truck with our{" "}
                  <span>hassle-free EMI options! </span>
                </h2>
              </div>
              <div className="col-4">
                <div className="buttonare">
                  <Link to="/finance" className="knowmore">
                    Know More
                  </Link>
                  <Link to="/finance" className="contactUs">
                    Contact Us
                  </Link>
                </div>
              </div>
            </div>

            {/* <div className="personal-detail-fields-box">
              <div className="field-icon-container name-grid-item">
                <Person className="input-field-icon" />
                <input
                  placeholder={"Full Name"}
                  name="fullName"
                  type="text"
                  onChange={onChnageHandler}
                  value={data?.fullName}
                />
              </div>

              <div className="field-icon-container phone-grid-item">
                <Phone className="input-field-icon" />
                <span>+91</span>
                <input
                  placeholder={"Phone Number"}
                  type="text"
                  name="mobileNumber"
                  onChange={onChnageHandler}
                  value={data?.mobileNumber}
                />
              </div>
              <div className="field-icon-container mail-grid-item">
                <Mail className="input-field-icon" />
                <input
                  placeholder={"Email Id "}
                  type="email"
                  name="email"
                  onChange={onChnageHandler}
                  value={data?.email}
                />
              </div>

              <div className="field-icon-container state-grid-item">
                <textarea
                  placeholder={"Enter Your Message "}
                  className="form-control"
                  name="message"
                  onChange={onChnageHandler}
                  value={data?.message}
                  style={{width:'100%',border:'none',resize:'none'}}
                />
              </div>
              <div className="">
                <button
                  class="btn_black"
                  type="submit"
                  onClick={appendSpreadsheet}
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </div> */}
          </div>
        ) : (
          <SubmitSection />
        )}
      </div>

      <ContactStrip />
      <Footer />
    </Wrapper>
  );
};

export default Contact;
