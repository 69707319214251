import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import logo from "../assets/Grouplogo.png";
import locationIcon from "../assets/Vectorlocation.svg";
import location3Icon from "../assets/Location3Icon.svg";
import searchIcon from "../assets/VectorsearchIcon.svg";
import CrossIcon from "../assets/CrossIcon.svg";
import Account from "../assets/Account.svg";

import { Link, useNavigate } from "react-router-dom";
import { get } from "../utils/functions";
import {
  makeAdded,
  modelAdded,
  brandAdded,
} from "../features/explore/exploreSlice";
import { useDispatch } from "react-redux";

import LeftArrow from "../assets/LeftIcon.svg";
import CurrentLocation2Icon from "../assets/CurrentLocation2.svg";
import TargetLocationIcon from "../assets/TargetLocationIcon.svg";
import PopupCityItem from "./PopupCityItem";
import LoginPopup from "./LoginPopup";
import AccountPopup from "./AccountPopup";

import RegisterPopup from "./RegisterPopup";
import { toast } from "react-hot-toast";
import useOnClickOutside from "../utils/useOnClickOutside";
import EstimatePopup from "./EstimatePopup";

const Wrapper = styled.div`
  border-bottom: 2px solid #e8e8e8;
  background-color: white;
  .navbar {
    width: 90%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 5fr;
    grid-gap: 2rem;
    background-color: ffffff;
    padding: 2rem;
    height: 5.5rem;
    position: relative;
  }

  .menu {
    display: grid;
    grid-template-columns: 18rem 2.5fr 1.1fr 1.3fr;
    grid-gap: 0.5rem;
    justify-items: center;
  }

  .location {
    height: 5.5rem;
    background-color: #f7f7f7;
    border-radius: 0.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 15rem;
    cursor: pointer;
    font-size: 1.4rem;
    padding-left: 14px;
    padding-right: 14px;
  }

  .location img {
    width: 1.3rem;
    margin-right: 2rem;
  }
  .search-container {
    display: flex;
    flex-direction: column;
    width: 30vw;
    margin: 0 auto;

    /* width: 90%;
    height: 5.5rem; */
    /* background-color: #f7f7f7; */
  }
  .results {
    margin: 1rem auto;
    width: 30vw;
    background-color: #f7f7f7;
    z-index: 10;
    /* padding: 2rem; */
    box-sizing: border-box;
    border-radius: 1rem;
    /* opacity: 0.9; */
    min-height: 300px;
    max-height: 600px;
    /* overflow-y: scroll; */
    overflow-x: hidden;
  }
  .rescon {
    width: 95%;
    height: 90%;
    overflow-y: scroll;
    margin: 0 auto;
    /* padding: 1rem; */
    /* padding: 1rem; */
  }
  .result-con {
    font-family: "Mulish";
    margin: 1rem auto;

    padding: 1rem;

    border: 1px solid #dddddd;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
  }
  .result-container {
    max-height: 200px;
    width: 100%;
    margin: 0 auto;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
  }
  .result-container::-webkit-scrollbar-thumb {
    height: 10%;
  }
  .result-con h1 {
    background-color: #665858;
    padding: 0.7rem;
    max-width: 100px;
    border-radius: 0.5rem;
    color: #d6d6d6;
    font-size: 16px;
  }
  .result-con button {
    background-color: #f1f1f1;
    outline: none;
    padding: 1rem;
    /* max-width: 100px; */
    width: 100%;
    border-radius: 0.5rem;
    margin: 1rem auto;
    color: #252424;
    font-size: 16px;
    border: 1px solid #929292;
    transition: 1s;
    opacity: 1;
    z-index: 100;
  }
  .result-con button:hover {
    background-color: #e4e2e2;
    outline: 1px solid black;
  }
  .result-con ul {
    padding: 0 auto;
    padding-left: 0;
  }
  .result-con li {
    padding: none;
    margin: none;
  }

  .selectcity {
    /* -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none; */
    border-style: none;
    font-size: 1.4rem;
    width: 13rem;
    text-align: center;
    background-color: #f7f7f7;
    padding: 0 20px;
  }

  .searchbar {
    width: 100%;
    height: 5.5rem;
    background-color: #f7f7f7;
    display: flex;
    border-radius: 0.8rem;
    min-width: 30rem;
    margin: 0 auto;
  }

  .searchbar div,
  .searchbar input {
    width: 90%;
    padding: 0 2rem;
    display: flex;
    align-items: center;
  }

  .searchbar-small-screen {
    display: none;
  }

  .searchbar input {
    border-style: none;
    outline: none;
    font-size: 1.4rem;
    background-color: #f7f7f7;
  }

  .searchbar img {
    width: 1.8rem;
  }

  .links {
    display: flex;
    min-width: 20rem;
    /* max-height: 50px; */
    margin-top: 10px;
  }
  .links ul {
    list-style: none;
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: flex-start;
    color: #757575;
    padding: 0;
  }

  .links ul li {
    font-size: 1.4rem;
  }

  .nav-links {
    text-decoration: none;
    color: #757575;
    /* margin: 0.5rem; */
    text-align: center;
    width: 100%;
  }

  .btn {
    width: 12rem;
    height: 5.5rem;
    border-radius: 0.8rem;
    font-family: "Mulish";
    font-style: normal;
    font-weight: 600;
    font-size: 1.4rem;
  }

  .nav-buttons {
    display: flex;
    justify-content: space-around;
    min-width: 26rem;
  }
  .btn-outline-white {
    color: #000;
    background-color: #ffffff;
    border: 0.1rem solid #d5d5d5;
  }

  .btn-outline-white:hover {
    background-color: #f7f7f7;
    transform: scale(1.1);
    transition: all 0.1s ease-in-out;
  }
  .btn-register {
    /* color: #131313; */
    color: #ffffff;
    background-color: #050505;
    /* border: 0.1rem solid #d5d5d5; */
  }
  .btn-register:hover {
    background-color: #464646;
    transform: scale(1.1);
    transition: all 0.1s ease-in-out;
  }
  .contain {
    /* margin-top: 1rem; */
  }

  .btn-fill-dark {
    color: #ffffff;
    background-color: #000000;
  }
  .btnbtn {
    /* border: 3px solid blue; */
  }
  .acc-btn {
    display: flex;
    background: none;
    justify-content: space-between;
    border: none;
    cursor: pointer;
    align-items: center;
    height: fit-content;
  }
  .acc-div {
    display: flex;
    justify-content: center;
    height: fit-content;
    align-items: center;
  }
  .acc-btn p {
    margin-left: 1rem;
    color: #5e636f;
    font-size: 14px;
    font-family: Mulish;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  .btn-fill-dark:hover {
    background-color: #252525;
    transform: scale(1.1);
    transition: all 0.1s ease-in-out;
  }
  .margin {
    margin-left: 9vw;
  }
  .menu-icon {
    cursor: pointer;
    display: inline-block;
    height: 40px;
    margin: auto;
    position: relative;
    user-select: none;
  }
  .menu-icon .navicon {
    background: #333;
    display: block;
    height: 2px;
    position: relative;
    transition: background 0.2s ease-out;
    width: 18px;
  }

  .menu-icon .navicon:before,
  .menu-icon .navicon:after {
    background: #333;
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    transition: all 0.2s ease-out;
    width: 100%;
  }

  .menu-icon .navicon:before {
    top: 5px;
  }

  .menu-icon .navicon:after {
    top: -5px;
  }

  /* menu btn */
  .menu-btn,
  .menu-icon {
    display: none;
  }

  .menu-btn:checked ~ .menu-icon .navicon {
    background: transparent;
  }

  .menu-btn:checked ~ .menu-icon .navicon:before {
    transform: rotate(-45deg);
  }
  .menu-btn:checked ~ .menu-icon .navicon:after {
    transform: rotate(45deg);
  }

  .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
  .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
    top: 0;
  }

  // .location-popup {
  //   position: fixed;
  //   background-color: #ffffff;
  //   width: 100%;
  //   height: 100vh;
  //   top: 0;
  //   bottom: 0;
  //   left: 0;
  //   right: 0;
  //   z-index: 10;
  // }
  .location-popup {
position: absolute;
    background-color: rgb(255, 255, 255);
    width: 75%;
    height: 75vh;
    inset: 0px;
    margin: 0 auto;
    z-index: 10;
    top: 100px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  .location-popup-hide,
  .location-popup .location-mode-container-hide,
  .popup-search-location-container-hide {
    display: none;
  }
  .location-popup-show,
  .location-popup .location-mode-container-show,
  .popup-search-location-container-show {
    display: grid;
    overflow-y: scroll;
  }
  .popup-inner {
    width: 70%;
    margin: 0 auto;
    padding: 2rem;
    box-sizing: border-box;
  }
  .popup-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .popup-heading span {
    background: #f6af33;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .popup-heading h3 {
    font-size: 3.5rem;
    margin-bottom: 1.7rem;
  }
  .popup-heading p {
    font-size: 2rem;
    margin: 0;
    color: #727e8c;
  }

  .popup-heading span img {
    width: 2rem;
    cursor: pointer;
  }

  .popup-heading span img:hover {
    transform: scale(1.2);
    border: 1px solid linear-gradient(#f6c833, #f69133);
  }
  .popup-searchbar {
    margin: 5rem 0 0 0;
    width: 80%;
    display: grid;
    grid-template-columns: 1fr 6fr;
    align-items: center;
    padding: 2rem 0;
  }

  .popup-searchbar p {
    font-size: 1.8rem;
    background: #f6af33;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    width: 10rem;
    font-weight: 600;
    margin-right: 10rem;
    background-color: white;

    border-style: none;
    display: flex;
    cursor: pointer;
  }

  .popup-searchbar p img {
    transform: rotate(180deg);
    margin-right: 1rem;
  }

  .popup-searchbar-inner {
    background-color: #f7f7f7;
    border: 1px solid #e1e1e1;
    border-radius: 0.8rem;
    padding: 1rem;
  }

  .popup-searchbar-inner input {
    background-color: #f7f7f7;
    outline: none;
    border-style: none;
    font-size: 1.7rem;
    padding: 0.5rem 2rem;
    width: 80%;
  }
  .popup-searchbar-dis {
    background-color: #f7f7f7;
    border: 1px solid #e1e1e1;
    border-radius: 0.8rem;
    padding: 1rem;
    margin: 0 auto;
    overflow-y: scroll;
    /* width: 50vw; */
    /* width: 80vw; */
  }

  .popup-searchbar-dis input {
    background-color: #f7f7f7;
    outline: none;
    border-style: none;
    font-size: 1.7rem;
    padding: 0.5rem 2rem;
    width: 50vw;
    margin: 0 auto;
  }

  .popup-search-results {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    place-items: center;
  }
  .popup-search-results button {
    color: #555555;
    font-size: 20px;
    padding: 1rem 1.5rem;
    border: 1px solid #b4b4b4;
    border-radius: 5px;
    background-color: #ececec;
    margin: 1rem;
  }

  .location-mode-container {
    display: grid;
    grid-template-columns: 1fr 0.3fr 1fr;
    /* place-items: center; */
    margin: 5rem 0 0 0;
  }

  .location-mode {
    display: flex;
    gap: 3rem;
  }

  .mode-content {
    width: 100%;
  }

  .mode-icon img {
    width: 4rem;
    border: 1px solid #f69133;
    opacity: 0.6;
    padding: 2rem;
    border-radius: 0.8rem;
  }

  .mode-content h3 {
    font-size: 2.2rem;
  }

  .mode-content p {
    font-size: 1.4rem;
    margin-bottom: 3rem;
    color: #929292;
  }

  .mode-content button {
    font-size: 1.5rem;
    background-color: #f8b301;
    border: 1px solid #f8b301;
    border-radius: 0.8rem;

    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 5rem;
    width: 100%;
  }

  .mode-content button img {
    width: 2rem;
    margin-right: 2rem;
  }
  /* .mode-divider {
    margin-right: 3rem;
  } */
  .mode-divider h4 {
    font-size: 2rem;
    text-align: center;
    color: #929292;
  }
  @media (max-width: 1500px) {
    .popup-inner {
      width: 90%;
    }
  }

  @media only screen and (min-width:1200px) and (max-width:1440px)
  {
    .location-popup {
    position: fixed;
    background-color: #ffffff;
    width: 75%;
    height: 75vh;
    top: 100px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    margin:0 auto;
  }
  }

  @media (max-width: 1200px) {
    .navbar {
      grid-template-columns: 2fr 4fr 0.3fr;
    }
    .menu-icon {
      position: absolute;
      top: 30px;
      right: 30px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
    .menu-btn:not(:checked) ~ .menu {
      display: none !important;
    }
    .menu-btn:checked ~ .menu {
      position: absolute;
      box-sizing: border-box;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 2rem;
      z-index: 10;
      padding: 2rem;
      border-radius: 0.8rem;
      top: 9rem;
      width: 100%;
      background-color: #ffffff;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
        rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    }

    /* .searchbar-small-screen {
      width: 100%;
      height: 5.5rem;
      background-color: #f7f7f7;
      display: flex;
      border-radius: 0.8rem;
      min-width: 30rem;
      margin: 0 auto;
    } */
    .searchbar-small-screen {
      width: 70vw;
      margin: 0 auto;
    }
    .searchbar-small-screen input {
      width: 90%;
      padding: 0 8px;
      display: flex;
      align-items: center;
    }

    .searchbar-small-screen input {
      border-style: none;
      outline: none;
      font-size: 16px;
      background-color: #f7f7f7;
    }
    .searchinput {
      width: 90%;
      height: 30px !important;
      margin-top: 5px;
      display: flex;
      background-color: #f7f7f7;
      border-radius: 1rem;
    }

    .searchbar-small-screen img {
      width: 1.8rem;
    }

    .searchbar-small-screen {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .results {
      width: 95%;
      box-shadow: 0px 11px 21px 1px rgba(0, 0, 0, 0.75);
    }
    .results li {
      width: 70%;
      margin: 0.5rem auto;
    }
    .searchinput {
      padding: 1rem;
    }

    .search-container {
      display: none;
    }
    .acc-btn {
      margin: auto;
    }
  }

  @media (max-width: 1000px) {
    .location-mode-container {
      display: grid;
      grid-template-columns: 1fr;
      /* place-items: center; */
      margin: 5rem 0 0 0;
    }

    .popup-inner {
      padding: 2rem;
    }
  }

  @media (max-width: 800px) {
    .searchbar-small-screen {
      width: 60vw;
    }
    .menu-btn:checked ~ .menu {
      grid-template-columns: 1fr;
      /* justify-items: center; */
    }
    .links ul {
      flex-direction: column;
      align-items: center;
    }
    .links ul li {
      margin: 1rem;
    }
    .nav-links {
      margin: 2rem;
    }

    /* .menu{
      grid-template-columns: 1fr;
    } */
    .acc-btn {
      margin: 1rem;
    }
    .location {
      max-width: 100px;
    }

    .logo {
      /* display: flex;
      align-items: center; */
      /* width: 10px; */
      /* border: 1px solid red; */
    }

    .logo img {
      /* width: 1rem !important; */
      /* border: 1px solid red; */
    }

    .popup-heading {
      display: flex;
      align-items: flex-start;
    }

    .popup-heading h3 {
      font-size: 2rem;
      margin-bottom: 1.5rem;
    }
    .popup-heading p {
      font-size: 1.5rem;
    }

    .popup-heading span img {
      width: 1.5rem;
      cursor: pointer;
      margin-top: 2.5rem;
    }

    .mode-icon img {
      width: 2rem;
      padding: 1rem;
    }

    .mode-content h3 {
      font-size: 1.5rem;
    }

    .mode-content p {
      font-size: 1.2rem;
      margin-bottom: 2rem;
    }

    .mode-content button {
      font-size: 1.2rem;
      background-color: #f8b301;
      border: 1px solid #f8b301;
      border-radius: 0.8rem;

      font-weight: 600;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 5rem;
      width: 100%;
    }

    .mode-content button:hover {
      transform: scale(1.3);
      /* animation: transform 0.1s ease-in-out; */
    }

    .mode-content button img {
      width: 2rem;
      margin-right: 2rem;
    }

    .popup-searchbar {
      margin: 5rem 0 0 0;
      width: 100%;
      display: grid;
      grid-template-columns: 1fr;
    }
  }

  @media (max-width: 600px) {
    .navbar {
      padding: 0.5rem;
      grid-gap: 0.5rem;
    }
    .menu-btn:checked ~ .menu {
      justify-items: center;
    }
    .links ul {
      justify-content: center;
    }
    .links ul li {
      margin: 1rem auto;
    }
    .searchinput {
      height: 35px;
    }

    .searchbar-small-screen input{
      font-size:12px
    }
    .searchbar-small-screen {
      margin-left: 28vw;
      width: 48vw;
    }
    .search-container {
      display: none;
    }
    .results {
      width: 70vw;
      /* opacity: 1; */
    }
    .menu-icon {
      right: 5px;
      top: 15px;
    }
    .logo {
      position: absolute;
      top: 15px;
      left: -7px;
    }
    .logo img {
    }

    /* .location {
        grid-area: menuitems;
        grid-row: 1;
      }
      .links {
        grid-area: menuitems;
        grid-row: 2;
      }
      .nav-buttons {
        grid-area: menuitems;
        grid-row: 3; */
    /* } */

    .logo img {
      width: 8rem;
    }

    .searchbar {
      min-width: 20rem;
    }

    .searchbar div,
    .searchbar input {
      padding: 0;
      width: 100%;
      background-color: #fff;
    }

    .searchbar img {
      width: 2rem;
    }
    .results {
      /* border: 1px solid red; */
      position: absolute;
      top: 57px;
      left: 5vw;
      width: 80vw;
    }
  }
`;

const Navbar = ({ link, search }) => {
  const [toggle, setToggle] = useState("hide");
  const [location, setLocation] = useState("Select Location");
  const [popup, setPopup] = useState("show");
  const [manualPopup, SetManualPopup] = useState("hide");
  const [geo, setGeo] = useState({});
  const [val, setVal] = useState("");
  const [rto, setRto] = useState([]);
  const [searchResult, setSearchResults] = useState({});
  const [selectedState, setSelectedState] = useState();
  const [dis, setDis] = useState([]);
  const [selectedDist, setSelectedDist] = useState();
  const [user, setuser] = useState();
  const [estimate, setEstimate] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [showaccount, setShowaccount] = useState(false);

  const [searchState, setSearchState] = useState("");
  const [searchDistrict, setSearchDistrict] = useState("");
  const ref = useRef(null);
  useOnClickOutside(ref, () => setVal(""));

  const navigate = useNavigate();
  const dispatch = useDispatch();
  // //"rto", rto);
  useEffect(() => {
    val !== "" &&
      (async () => {
        try {
          const resp = await get(`/buy/new/search`, { value: val }, {});
          if (resp.status_code === 200) setSearchResults(resp.data);
        } catch (error) {}
      })();
  }, [val, manualPopup]);

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("account"));
    if (userData !== undefined) setuser(userData);
  }, [localStorage.getItem("token")]);
  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("rto"));
    // //userData)
    if (userData !== undefined) setLocation(userData?.district.district);
  }, [localStorage.getItem("rto")]);
  // //"search resp", rto);

  useEffect(() => {
    const cities = document.querySelectorAll(".city-item");
    cities.forEach((city) => {
      city.addEventListener("click", (e) => {
        // setLocation(e.target.innerText);
        setToggle("hide");
      });
    });
    const dist = JSON.parse(localStorage.getItem("rto"));
    if (dist !== undefined) setLocation(dist?.district.district);
    else setLocation("Select Location");
  }, []);

  useEffect(() => {
    manualPopup === "show" &&
      (async () => {
        try {
          const resp = await get("/general/rto/state", {}, {});
          console.log("third");
          // //resp);
          setRto(resp?.data);
          // if(resp.status_code===200)  setStates(resp.data)
        } catch (error) {}
      })();
  }, [manualPopup]);

  useEffect(() => {
    selectedState &&
      (async () => {
        try {
          const resp = await get(
            `/general/rto/code/state/${selectedState?.id}`,
            {},
            {}
          );
          // //resp);
          setDis(resp?.data);
          // if(resp.status_code===200)  setStates(resp.data)
        } catch (error) {}
      })();
  }, [selectedState]);
  const handleGeolocation = async () => {
    // localStorage.removeItem('rto');
    try {
      const position = await new Promise((resolve, reject) => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        } else {
          reject("Geolocation is not supported by this browser.");
        }
      });

      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      // //latitude, longitude);
      const resp = await get(
        "/general/getlocation",
        { lat: latitude, long: longitude },
        {}
      );
      if (resp.status_code === 200) {
        const { state, district } = resp.data;
        const rto = {
          state,
          district,
        };
        localStorage.setItem("rto", JSON.stringify(rto));
        setLocation(resp.data.district.district);
        handleToggle();

        // window.location.reload(true);
      }
      if (resp.status_code === 400) {
        // setLocation();

        toast.error(`${resp.message}. Try Manually`);
      }
      //resp,"geo")
      // //resp);
    } catch (error) {
      toast.error(error);
    }
  };

  const handleStateInput = (e) => {
    // //e.target.value, "onchange");
    setSearchState(e.target.value);
  };

  const handleManualLocation = () => {
    // popup === "show" && setPopup("hide");
    SetManualPopup("show");
  };
  const handleDistrictSelect = (e) => {
    e.preventDefault();

    const district = JSON.parse(e.target.value);
    // //district)
    const rto = {
      state: selectedState,
      district,
    };
    localStorage.setItem("rto", JSON.stringify(rto));
    // window.location.reload(true);

    setLocation(district.district);
    setSelectedState();
    // setLocation(JSON.parse(e.target.value).name)

    handleToggle();
  };

  const handleToggle = () => {
    toggle === "show" && setToggle("hide");
    toggle === "hide" && setToggle("show");
  };
  const handleManualToggle = () => {
    if (manualPopup === "show") {
      SetManualPopup("hide");
      // setToggle("show");
    }
    if (manualPopup === "hide") {
      SetManualPopup("show");
      // setToggle("hide");
    }
  };

  const handleMake = (e) => {
    dispatch(makeAdded({ name: e.target.value }));
    navigate("/second-hand-truck-for-sale?makename=" + e.target.value);
    setVal("");
  };
  const handleModel = (e) => {
    dispatch(modelAdded(e.target.value));
    navigate("/second-hand-truck-for-sale?modelname=" + e.target.value);
    setVal("");
  };

  const handleBrand = (e) => {
    dispatch(brandAdded(e.target.value));
    navigate("/second-hand-truck-for-sale?brandname=" + e.target.value);
    setVal("");
  };

  function handleLoginClick() {
    setShowLoginPopup(true);
  }

  function handleLoginPopupClose() {
    setShowLoginPopup(false);
  }
  function handleAccountClose() {
    setShowaccount(!showaccount);
  }

  function handleEstimateToggle() {
    setEstimate((estimate) => !estimate);
  }

  const [showRegisterPopup, setShowRegisterPopup] = useState(false);

  function handleRegisterClick() {
    setShowRegisterPopup(true);
  }

  function handleRegisterPopupClose() {
    setShowRegisterPopup(false);
  }

  function handleSell(e) {
    e.preventDefault();
    navigate("/sell-vehicle");
  }
  // console.log(estimate)

  return (
    <Wrapper>
      <div className="navbar">
        {/* logo */}
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="Logo" />
          </Link>
        </div>
        {/* collapse icon */}
        {search && (
          <div className="searchbar-small-screen">
            <div className="searchinput">
              <input
                type="text"
                placeholder="Search by Model, Type, Name..."
                value={val}
                onChange={(e) => setVal(e.target.value)}
              />
              <img src={searchIcon} alt="search icon" />
            </div>

            {val !== "" && (
              <div className="results">
                <div className="rescon">
                  {/* <div className="result-con">
                    <h1>Make</h1>
                    <div className="result-container">
                      <ul>
                        {searchResult?.make?.map((res) => (
                          <button
                            key={res.id}
                            value={res.variant_name}
                            onClick={handleMake}
                          >
                            {res.name}
                          </button>
                        ))}
                      </ul>
                    </div>
                  </div> */}
                  <div className="result-con">
                    {/* <h1>Model Name</h1> */}
                    <div className="result-container">
                      <ul>
                        {searchResult?.brandName?.length > 0 &&
                          searchResult?.brandName?.map((res) => (
                            <button
                              type="button"
                              key={"brand" + res.brand_id}
                              value={res.brand_name}
                              onClick={handleMake}
                            >
                              {res.brand_name}
                            </button>
                          ))}

                        {searchResult?.modelName?.length > 0 &&
                          searchResult?.modelName?.map((res) => (
                            <button
                              type="button"
                              key={"button" + res.id}
                              value={res.model_name}
                              onClick={handleModel}
                            >
                              {res.model_name}
                            </button>
                          ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}

        <input className="menu-btn" type="checkbox" id="menu-btn" />
        <label className="menu-icon" htmlFor="menu-btn">
          <span className="navicon"></span>
        </label>
        {/* <div className="menu-container">

        </div> */}
        <div className="menu">
          {/* city selector */}
          <div
            id="location"
            className="nav-items location"
            onClick={handleToggle}
          >
            <div className="align-items-vertically">
              <img src={locationIcon} alt="location icon" />
              {location ? (
                <span>{location}</span>
              ) : (
                <span>Select Location</span>
              )}
            </div>
          </div>

          <div
            id="location-popup"
            className={`location-popup location-popup-${toggle}`}
          >
            <div className="popup-inner">
              <div className="popup-heading">
                <div>
                  <h3>
                    Please choose your <span>location</span>
                  </h3>
                  <p>Help us with your location so we can serve you better.</p>
                </div>
                <span onClick={handleToggle}>
                  <img src={CrossIcon} alt="" />
                </span>
              </div>
              <div
                className={`location-mode-container location-mode-container-${popup}`}
              >
                <div className="location-mode">
                  <div className="mode-icon">
                    <img
                      src={CurrentLocation2Icon}
                      alt="current location icon"
                    />
                  </div>
                  <div className="mode-content">
                    <h3>Use my current location</h3>

                    <p>We’ll need permission to use your device’s location</p>

                    <button
                      className=""
                      type="button"
                      onClick={handleGeolocation}
                    >
                      <img
                        src={TargetLocationIcon}
                        alt="current location icon"
                      />{" "}
                      USE CURRENT LOCATION
                    </button>
                  </div>
                </div>
                <div className="mode-divider">
                  <div className="dashed-line"></div>
                  <h4>OR</h4>
                </div>
                <div className="location-mode">
                  <div className="mode-icon">
                    <img src={location3Icon} alt="manual location icon" />
                  </div>
                  <div className="mode-content">
                    <h3>Select Manually</h3>

                    <p>
                      Search by your city.
                      {/* Also you can search by state and then */}
                      {/* choose city. */}
                    </p>

                    <button
                      className=""
                      type="button"
                      onClick={handleManualLocation}
                    >
                      {/* <img src={location3Icon} alt="manual location icon" />{" "} */}
                      SELECT MANUALLY
                    </button>
                  </div>
                </div>
              </div>
              <div
                className={`popup-search-location-container popup-search-location-container-${manualPopup}`}
              >
                {!selectedState && (
                  <div>
                    <div className="popup-searchbar">
                      <p onClick={handleManualToggle}>
                        <img src={LeftArrow} alt="left orange arrow icon" />{" "}
                        Back
                      </p>
                      <div className="popup-searchbar-inner">
                        <input
                          type="text"
                          placeholder="Search for your State"
                          value={searchState}
                          onChange={(e) => setSearchState(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="popup-search-results">
                      {rto
                        ?.filter((state) =>
                          state.state
                            .toLowerCase()
                            .includes(searchState.toLowerCase())
                        )
                        .map((state) => {
                          // //state);
                          return (
                            // <h2>{state?.name}</h2>

                            <button
                              key={state.id}
                              value={JSON.stringify(state)}
                              onClick={(e) => {
                                setSelectedState(JSON.parse(e.target.value));
                                setSelectedDist();
                              }}
                            >
                              {state.state}
                            </button>
                          );
                        })}
                    </div>
                  </div>
                )}
                {selectedState && (
                  <div className="contain">
                    <div className="popup-searchbar ">
                      <p
                        onClick={() => {
                          setSelectedState();
                        }}
                      >
                        <img src={LeftArrow} alt="left orange arrow icon" />{" "}
                        Back
                      </p>
                      <div className="popup-searchbar-dis">
                        <input
                          type="text"
                          placeholder="Search for your District"
                          value={searchDistrict}
                          onChange={(e) => setSearchDistrict(e.target.value)}
                        />
                      </div>
                    </div>
                    <div className="popup-search-results">
                      {dis
                        ?.filter((state) =>
                          state.district
                            .toLowerCase()
                            .includes(searchDistrict.toLowerCase())
                        )
                        .map((state) => {
                          // //state);
                          return (
                            // <h2>{state?.name}</h2>

                            <button
                              key={state.id}
                              value={JSON.stringify(state)}
                              onClick={handleDistrictSelect}
                            >
                              {state.district}
                            </button>
                          );
                        })}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* searchbar */}
          {search ? (
            <div className="search-container">
              <div className="searchbar searchbar-dropdown-hidden">
                <div className="align-items-vertically ">
                  <input
                    type="text"
                    placeholder="Search by Model, Type, Name..."
                    value={val}
                    onChange={(e) => setVal(e.target.value)}
                  />
                  <img src={searchIcon} alt="search icon" />
                </div>
              </div>
              {val !== "" && (
                <div className="results">
                  <div className="rescon">
                    {/* <div className="result-con">
                      <h1>Make</h1>
                      <div className="result-container">
                        <ul>
                          {searchResult?.make?.map((res) => (
                            <button
                              key={res.id}
                              value={res.name}
                              onClick={handleMake}
                            >
                              {res.name}
                            </button>
                          ))}
                        </ul>
                      </div>
                    </div> */}
                    <div className="result-con">
                      <div className="result-container">
                        <ul>
                          {searchResult?.brandName?.length > 0 &&
                            searchResult?.brandName?.map((res) => (
                              <button
                                type="button"
                                key={"brand" + res.brand_id}
                                value={res.brand_name}
                                onClick={handleMake}
                              >
                                {res.brand_name}
                              </button>
                            ))}

                          {searchResult?.modelName?.length > 0 &&
                            searchResult?.modelName?.map((res) => (
                              <button
                                type="button"
                                key={"button" + res.id}
                                value={res.model_name}
                                onClick={handleModel}
                              >
                                {res.model_name}
                              </button>
                            ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className=""></div>
          )}

          {/* nav links */}
          <div className="links">
            <ul>
              {/* <li style={{ position: "relative" }}>
                <button
                  className="nav-links"
                  onClick={() => {
                    handleEstimateToggle()
                  }}
                  style={{ margin: 0 }}
                  disabled={estimate}
                >
                  Estimate
                </button>
                {estimate && (
                  <EstimatePopup onClose={handleEstimateToggle} />
                )}
              </li> */}
              {/* <li>
                <Link className="nav-links" to="/aboutus">
                  About
                </Link>
              </li> */}
              <li>
                <Link className="nav-links" to="/sell-vehicle">
                  Sell
                </Link>
              </li>
              <li>
                <Link className="nav-links" to="/contact-us">
                  Contact Us
                </Link>
              </li>
              {/* {link === "/sell" ? (
                <li>
                  <Link className="nav-links" to="/sell" onClick={handleSell}>
                    Sell
                  </Link>
                </li>
              ) : (
                <li>
                  <Link className="nav-links" to="/">
                    Home
                  </Link>
                </li>
              )} */}
            </ul>
          </div>
          {/* login register */}
          {localStorage.getItem("token") ? (
            <div className="btnbtn">
              <div className="acc-div btnbtn">
                <button
                  className="acc-btn btnbtn"
                  onClick={(e) => {
                    handleAccountClose();
                  }}
                >
                  <img className="btnbtn" src={Account} alt="" />
                  <p className="btnbtn">Hi {user?.name?.split(" ")[0]}</p>
                </button>
              </div>
              {showaccount && <AccountPopup onClose={handleAccountClose} />}
            </div>
          ) : (
            <div className="nav-buttons">
              <button
                className="btn btn-outline-white "
                onClick={handleLoginClick}
              >
                Login
              </button>
              {showLoginPopup && (
                <LoginPopup
                  // ref = {ref}
                  onClose={handleLoginPopupClose}
                  onRegister={handleRegisterClick}
                />
              )}
              <button
                className="btn  btn-register"
                onClick={handleRegisterClick}
              >
                Register
              </button>
              {showRegisterPopup && (
                <RegisterPopup
                  onClose={handleRegisterPopupClose}
                  onLogin={handleLoginClick}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default Navbar;
