import React from "react";
import { Link } from "react-router-dom";
import './footer1.css'


const Footer = ({ show }) => {
    return (
        <footer className="footer">

            <div className="footer-column">
                <ul>
                    <li>
                        <Link className="footer-links" to="/privacy-policy">
                            Privacy Policy
                        </Link>
                    </li>
                    <li>
                        <Link to="/terms-and-conditions" className="footer-links">
                            Terms & Conditions
                        </Link>
                    </li>
                    <li>
                        <Link className="footer-links" to="tel:7021411346">
                            +91 7021411346
                        </Link>
                    </li>
                    <li>
                        <Link className="footer-links" to="mail:info@cavalo.in">
                            info@cavalo.in
                        </Link>
                    </li>
                </ul>
            </div>
        </footer>
    );
};

export default Footer;
