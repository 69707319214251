import {useEffect, useState} from "react"

const Banner = ({parameters}) => {
    const [isActive, setisActive] = useState(true);
    useEffect(() => {
        const interval = setInterval(() => {
        setisActive((prev) => !prev);
        }, 3000);

        const hr1 = document.getElementById("hr1");
        const hr2 = document.getElementById("hr2");

        if(isActive){
        hr2.classList.remove("active");
        hr1.classList.add("active");
        }else{
        hr1.classList.remove("active");
        hr2.classList.add("active");
        }
        return () => clearInterval(interval);
    }, [isActive]);
    return(
        <>
            <div id = "text1" className={`content ${isActive ? 'activeContent' : ''}`}>
              <h1>
                Best marketplace for <br />
                <span>preowned</span> trucks.
              </h1>
              <p>Get the best value on the purchase of refurbished trucks.</p>
            </div>

            <div id = "text2" className={`content ${isActive ? '' : 'activeContent moveUp'}`}>
              <h1>
                <span>Customize </span> trucks as <br /> per your preference.
              </h1>
              <p>Change or add truck accessories as per your needs..</p>
            </div>
        </>
    )}


export default Banner;