import React, { useEffect } from "react";
import { Navbar, ContactStrip, Footer, Faq } from "../components";
import Wrapper from "../wrappers/Common";

const FaqPage = () => {

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  return (
    <Wrapper>
      <Navbar search={true}/>
      <Faq />
      <ContactStrip />
      <Footer />
    </Wrapper>
  );
};

export default FaqPage;
