import React, { useState, useRef } from "react";
import styled from "styled-components";
import "./LoginPopup.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faLock,
  faMobile,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { get } from "../utils/functions";
import Lock from "../assets/Lock.svg";
import Phone from "../assets/Phone.svg";
import { toast } from "react-hot-toast";
import { CircularProgress } from "@mui/material";
import useOnClickOutside from "../utils/useOnClickOutside";

function LoginPopup(props) {
  const [number, setNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [isSend, setIsSend] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const ref = useRef(null);
  useOnClickOutside(ref, () => props.onClose(false));

  const onSendOtp = async (event) => {
    event.preventDefault();
    setOtpLoading(true);
    try {
      const resp = await get("/user/sendotp", { mobile_no: number });
      if (resp?.status_code === 200) {
        setIsSend(true);
        toast.success("OTP sent successfully");
      } else {
        toast.error(resp?.message);
        setIsSend(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error.message);
    } finally {
      setOtpLoading(false);
    }
  };

  const ValidateOtp = async (event) => {
    event.preventDefault();
    // setButtonLoading(true);
    try {
      if (number == "") {
        toast.error("Please Enter Your Phone");
        return;
      }
  

      if (number.length != 10) {
        toast.error("Invalid Phone");
        return;
      }
      if ( number.length > 10) {
        toast.error("Please Enter Valid Phone Number");
        return;
      }
      const resp = await get("/user/login", { mobile_no: number, otp: otp });
      if (resp?.data) {
        const { token, refresh_token } = resp.data;
        localStorage.setItem("token", token);
        localStorage.setItem("refresh_token", refresh_token);
        localStorage.setItem("account", JSON.stringify(resp.data));
        toast.success(resp?.message);
        props.onClose(); // Close the popup on successful login
      } else {
        toast.error(resp?.message);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || error.message);
    } finally {
      // setButtonLoading(false);
    }
  };

  return (
    <div className="login-popup">
      <div ref={ref} className="login-popup-inner">
        <div>
          <ul className="tab-group">
            <li className="tab active">
              <button>Login</button>
            </li>
            <li className="tab">
              <button
                onClick={() => {
                  props.onRegister();
                  props.onClose();
                }}
              >
                Register
              </button>
            </li>
          </ul>
        </div>
        <div className="login-popup-inner-inner">
          <div className="loginBar">
            <h1>Login Via OTP</h1>
            <button className="close-btn" onClick={props.onClose}>
              <FontAwesomeIcon icon={faXmark} className="cross" />
            </button>
          </div>
          <form onSubmit={ValidateOtp}>
            <label>
              <div className="input-container">
                <img src={Phone} className="mobile-icon" alt="Phone" />
                <input
                  type="number"
                  value={number}
                  className="phone-input"
                  onChange={(e) => setNumber(e.target.value)}
                  placeholder="Phone No."
                />
                {otpLoading ? (
                  <CircularProgress color="inherit" size={25} />
                ) : (
                  <button type="button" onClick={onSendOtp}>
                    GET OTP
                  </button>
                )}
              </div>
            </label>

            <label>
              <div className="input-container">
                <img src={Lock} className="mobile-icon" alt="Lock" />
                <input
                  type="number"
                  value={otp}
                  className="phone-input"
                  onChange={(e) => setOtp(e.target.value)}
                  placeholder="OTP"
                />
              </div>
            </label>

            <button
              type="submit"
              // disabled={!isSend || buttonLoading}
              // style={{ opacity: isSend ? 1 : 0.5, cursor: isSend ? 'pointer' : 'not-allowed' }}
            >
              {/* {buttonLoading ? (
                <CircularProgress color="inherit" size={25} />
              ) : (
                "Login"
              )}
              {!buttonLoading && <FontAwesomeIcon className="ms_2" icon={faArrowRight} />} */}
              Login
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}

export default LoginPopup;
